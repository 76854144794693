<template>
  <Fieldset legend="แบบฟอร์มบันทึกข้อมูลผู้ใช้งาน">
    <div class="p-d-flex p-jc-between">
      <div>
        <Button
          label="ย้อนกลับ"
          icon="pi pi-arrow-left"
          class="p-button-sm p-button-warning"
          @click="() => $router.back(-1)"
        />
      </div>
    </div>

    <form @submit.prevent="saveValidatedForm" id="validity">
      <div class="p-fluid p-grid">
        <!--<div class="p-field p-col-12 p-md-4">
          <label for="wight">รหัส User</label>
          <InputText
            id="code_id"
            type="text"
            v-model="forms.codeId"
            placeholder="รหัส User"
            :class="[validates['codeId']]"
            readonly
          />
        </div>-->

        <div class="p-field p-col-12 p-md-4">
          <label for="userLevel" ref="userLevel"
            >ระดับผู้ใช้งาน (User Level)</label
          >
          <Dropdown
            id="userLevel"
            v-model="forms.userLevel"
            :options="levels"
            optionLabel="name"
            optionValue="code"
            :class="[validates['userLevel']]"
            :required="true"
          />
          <InlineMessage v-if="validates['userLevel']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="area" ref="area">พื้นที่</label>
          <Dropdown
            id="areaId"
            v-model="forms.areaId"
            :options="$store.state.area.areas"
            optionLabel="areaName"
            optionValue="codeId"
            placeholder="พื้นที่"
            :class="[validates['areaId'] && userLevel != 'AL']"
            :filter="true"
            :required="userLevel != 'AL'"
            :disabled="userLevel == 'AL' || forms.userLevel == 'AC'"
          />
          <InlineMessage v-if="validates['areaId'] && userLevel != 'AL'"
            >ห้ามว่าง</InlineMessage
          >
        </div>

        <div class="p-field p-col-12 p-md-4" />

        <div class="p-field p-col-12 p-md-4">
          <label for="fname" ref="mobile">ชื่อ</label>
          <InputText
            id="fname"
            type="text"
            v-model="forms.fname"
            placeholder="ชื่อ"
            :class="[validates['fname']]"
            :required="true"
          />
          <InlineMessage v-if="validates['fname']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="lname">นามสกุล</label>
          <InputText
            id="lname"
            type="text"
            v-model="forms.lname"
            placeholder="นามสกุล"
            :class="[validates['lname']]"
            :required="true"
          />
          <InlineMessage v-if="validates['lname']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="email" ref="email">อีเมล</label>
          <InputText
            id="email"
            type="email"
            v-model="forms.email"
            placeholder="อีเมล"
            :class="[validates['email']]"
            :required="true"
          />
          <InlineMessage v-if="validates['email']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="mobile" ref="mobile">หมายเลขโทรศัพท์มือถือ</label>
          <InputMask
            id="mobile"
            type="text"
            v-model="forms.mobile"
            placeholder="หมายเลขโทรศัพท์มือถือ"
            :class="[validates['mobile']]"
            mask="9999999999"
            :required="true"
          />
          <InlineMessage v-if="validates['mobile']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="tel" ref="tel">หมายเลขโทรศัพท์บ้าน/ที่ทำงาน</label>
          <InputMask
            id="tel"
            type="text"
            v-model="forms.tel"
            placeholder="หมายเลขโทรศัพท์บ้าน/ที่ทำงาน"
            mask="999999999"
          />
        </div>

        <div class="p-field p-col-12 p-md-4"></div>

        <div class="p-field p-col-12 p-md-4">
          <label for="userName" ref="userName">Username สำหรับ Login</label>
          <InputText
            id="userName"
            type="text"
            v-model="forms.userName"
            placeholder="Username สำหรับ Login"
            :class="[validates['userName']]"
            :required="true"
          />
          <InlineMessage v-if="validates['userName']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="userPassword" ref="userPassword"
            >รหัสผ่านสำหรับ Login</label
          >
          <Password
            id="userPassword"
            v-model="forms.userPassword"
            placeholder="รหัสผ่านสำหรับ Login"
            :class="[validates['userPassword']]"
            toggleMask
            :required="true"
            appendTo="body"
          >
            <template #header>
              <h6>ใส่รหัสผ่าน</h6>
            </template>
            <template #footer>
              <Divider />
              <p class="p-mt-2">ข้อเสนอแนะ</p>
              <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                <li>ตัวพิมพ์เล็กอย่างน้อยหนึ่งตัว</li>
                <li>ตัวพิมพ์ใหญ่อย่างน้อยหนึ่งตัว</li>
                <li>ตัวเลขอย่างน้อยหนึ่งตัว</li>
                <li>ขั้นต่ำ 8 อักขระ</li>
              </ul>
            </template>
          </Password>
          <InlineMessage v-if="validates['userPassword']"
            >ห้ามว่าง</InlineMessage
          >
          <span id="append"></span>
        </div>
      </div>
      <div class="p-d-flex p-jc-center">
        <Button
          label="บันทึกข้อมูลเข้าระบบ"
          type="submit"
          icon="pi pi-save"
          class="p-button-success"
        />
        <Button
          label="ยกเลิก"
          type="button"
          icon="pi pi-arrow-left"
          class="p-button-link"
          @click="() => $router.back(-1)"
        />
      </div>
    </form>
  </Fieldset>
</template>
<script>
import * as moment from 'moment'
import { initCustomValidity } from '@/HTMLElement'
export default {
  layout: 'Private',
  data() {
    return {
      forms: {}
    }
  },
  computed: {
    validates() {
      const result = Object.keys(this.forms).reduce((fields, value) => {
        // console.log("key :==", value);
        const _value = this.forms[[value]]
        // console.log("_value :==", _value);
        //console.log("this.forms[value] :==", this.forms[value]);
        return {
          ...fields,
          [value]:
            _value == null || _value == 'null' || _value == ''
              ? 'p-invalid'
              : ''
        }
      }, {})
      //console.log("result fields ::==", result);
      return result
    },
    userLevel() {
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      return userLevel
    },
    levels() {
      const { codeId, userLevel: level } = this.$store.getters['oauth/session']
      if (codeId) {
        const { levels } = this.$store.state.constance
        return levels.filter((ul, i) => {
          const { code, name } = ul
          return level == 'AC' || (level == 'AL' && ['AL', 'OL'].includes(code))
        })
      } else {
        return []
      }
    }
  },
  async mounted() {
    await this.fetchData()
    await this.$store.dispatch('area/fetchAreasOrderNameTh')
    initCustomValidity('validity')
  },
  methods: {
    async fetchData() {
      const { id } = this.$route.params
      if (id) {
        const { status, message, data } = await this.$store.dispatch(
          'user/fetchUserById',
          id
        )
        //console.log(`data :: ${data}`);
        const { area } = data
        this.forms = {
          ...data,
          areaId: area?.codeId
        }
      } else {
        this.forms = {
          codeId: null,
          userLevel: 'OL',
          fname: null,
          lname: null,
          mobile: null,
          tel: null,
          email: null,
          userStatus: null,
          failAttempt: null,
          createDtm: null,
          createBy: null,
          areaId: null,
          userPassword: null,
          userName: null
          /*area: {
          codeId: 3,
          areaName: "เมืองศรีราชา",
        },*/
        }
      }
      // handler dropdown area
      const { codeId: userId, userLevel, area: userArea } = this.$store.getters[
        'oauth/session'
      ]
      if (userLevel == 'AL') {
        this.forms['areaId'] = userArea?.codeId
      }
    },
    async saveValidatedForm() {
      const { codeId: userId, userLevel: level } = this.$store.getters[
        'oauth/session'
      ]

      // validate dropdown & elements
      const dropdowns = ['userLevel']
      const invalidates = dropdowns
        .filter((k) => {
          return !this.forms[k]
        })
        .map((k) => this.$refs[k].innerText)
      console.log('invalidates::==', invalidates)

      if (invalidates.length > 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'สถานะการทำรายการ',
          detail: `กรุณากรอกข้อมูล ${invalidates.join(
            ', '
          )}  ก่อนการกดปุ่มบันทึกข้อมูล`,
          life: 3000
        })
        return
      }

      if (this.isDupicate()) {
        const { userName } = this.forms
        this.$toast.add({
          severity: 'error',
          summary: 'สถานะการทำรายการ',
          detail: `user : ${userName} นี้มีการใช้งานแล้ว กรุณาตรวจสอบ`,
          life: 3000
        })
      } else {
        //confirm before  save
        const { dateFormatGet, dateFormatSave } = this.$primevue.config.locale
        this.$confirm.require({
          ...this.$primevue.config.locale,
          reject: async () => {
            const { areaId } = this.forms
            const payload = {
              ...this.forms,
              area: {
                codeId: areaId
              },
              userStatus: 'active',
              createDtm: moment().format(dateFormatSave),
              createBy: userId
            }
            if (areaId == null) {
              delete payload['area']
            }
            const {
              status,
              code,
              data,
              invalidCode
            } = await this.$store.dispatch('user/saveUser', payload)
            console.log('status ::==', status)
            console.log('code ::==', code)
            console.log('invalidCode ::==', invalidCode)
            // console.log("data ::==", data);
            if (200 == code) {
              this.$router.back(-1)
            } else {
              /*
               * 101 = profile not found
               * 102 = password incorrect
               * 103 = ?
               */
              let message =
                'แก้ไขข้อมูลส่วนตัวของคุณ ไม่สำเร็จ กรุณาติดต่อผู้ดูแลระบบ'
              if ('101' == invalidCode) {
                message = 'username ซ้ำ ไม่สามารถใช้งานได้'
              } else if ('102' == invalidCode) {
                message = 'username ซ้ำ ไม่สามารถใช้งานได้'
              }
              this.$toast.add({
                severity: 'error',
                summary: 'สถานะการทำรายการ',
                detail: message,
                life: 3000
              })
            }
          }
        })
      }
    },
    isDupicate() {
      const { userName } = this.forms
      const usr = this.$store.state.user.users.find(
        (user) => user.codeId == null && user.userName == userName
      )
      console.log('usr ::==', usr)
      return usr
    }
  }
}
</script>

<style lang="scss" scoped>
#validity {
  :deep(#userPassword) {
    z-index: 9999;
  }
}
</style>